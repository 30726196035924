/* COLORS

purple: #a67bff
light-grey: #dadada
white-grey: #f5f5f5 */

.App {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: black;
}

.links {
  display: flex;
  margin-top: 15px;
  margin-right: 25px;
}

.link-item {
  padding: 5px;
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 2vmin);
  color: white;
  margin: 0 auto;
  position: relative;
  background-color: #a67bff;
  padding: 15px;
  height: 100vh;
}

.header-bg {
    position: absolute;
    background-color: #a67bff;
    height: 200px;
    width: 100%;
    /* transform: rotate(-10deg); */
}

.welcome {
  text-shadow: 1px 1px 1px black;
  color: white;
}

.App-features {
  width: 85%;
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 125px;
}

.App-about {
  width: 85%;
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 125px;
}

.faq-question {
  text-align: left;
  margin: 0;
}

.faq-answer {
  text-align: left;
  margin: 0;
}

.faq-item {
  padding-top: 50px;
}

.feature {
  padding: 25px;
  height: 100%;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 20px;
  border-color: #a67bff;
  border-width: 3px;
  /* border-style: solid; */
}

.App-portfolio {
  position: relative;
  width: 85%;
  max-width: 1080px;
  margin: 0 auto;
  margin-top: 75px;
}

.portfolio-item {
  position: relative;
}

.portfolio-image {
  background-color: #f5f5f5;
  border-radius: 20px;
}

h5 {
  color: #a67bff;
}

.scroll-down {
  text-shadow: 1px 1px 1px black;
}

.purple-text {
  color: #a67bff;
}

.white-text {
  color: white;
}

.arrow {
  /* margin-top: 15vh; */
  animation: moveDownUp 1s infinite;
}

@keyframes moveDownUp {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
}

.external-buttons {
  margin: 5px;
  border-radius: 10px;
}

.clickable-text:hover {
  cursor: pointer !important;
}

.sitemap-item {
  text-align: left;
  width: 150px;
  margin: 0 auto;
}

.gradient-text {
  font-size: 48px;
  background: linear-gradient(to right, purple,#a67bff, #D2E3C8, #86A789); /* Define your gradient colors here */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.gradient-text2 {
  font-size: 48px;
  background: linear-gradient(to right, white, #D2E3C8, #86A789); /* Define your gradient colors here */
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.shadow-text {
  text-shadow:1px 1px 1px grey;/* Reset any existing text shadow */
}

/* Styles for screens smaller than 768px (mobile screens) */
@media (max-width: 925px) {
  .portfolio-container {
    position: relative;
    height: 350px;
    /* Add other styles for mobile screens here */
  }
  .portfolio-img {
    height: 225px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-left: 2px solid white;
    border-right: 2px solid white;
    border-top: 2px solid white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  body {
    font-size: 12px;
  }
}

/* Styles for screens 768px wide and larger (tablets, desktops, etc.) */
@media (min-width: 925px) {
  .portfolio-container {
    /* min-height: 100%; */
    /* Add other styles for larger screens here */
    position: relative;
    height: 350px;
  }
  .portfolio-img {
    position: absolute;
    height: 225px;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border-left: 5px solid white;
    border-right: 5px solid white;
    border-top: 5px solid white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  body {
    font-size: 18px;
  }
}